import React from 'react'

const FirebaseContext = React.createContext(null)

export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
        {({ firebase, shop }) => <Component {...props} firebase={firebase} shop={shop} />}
    </FirebaseContext.Consumer>
)

export default FirebaseContext