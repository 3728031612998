// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-app-settings-content-js": () => import("./../../../src/pages/app/settings/content.js" /* webpackChunkName: "component---src-pages-app-settings-content-js" */),
  "component---src-pages-app-settings-index-js": () => import("./../../../src/pages/app/settings/index.js" /* webpackChunkName: "component---src-pages-app-settings-index-js" */),
  "component---src-pages-app-store-info-js": () => import("./../../../src/pages/app/store-info.js" /* webpackChunkName: "component---src-pages-app-store-info-js" */),
  "component---src-pages-enable-cookies-js": () => import("./../../../src/pages/enable-cookies.js" /* webpackChunkName: "component---src-pages-enable-cookies-js" */),
  "component---src-pages-error-charge-declined-js": () => import("./../../../src/pages/error/charge-declined.js" /* webpackChunkName: "component---src-pages-error-charge-declined-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-js": () => import("./../../../src/pages/install.js" /* webpackChunkName: "component---src-pages-install-js" */),
  "component---src-pages-reauth-js": () => import("./../../../src/pages/reauth.js" /* webpackChunkName: "component---src-pages-reauth-js" */)
}

