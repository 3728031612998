import axios from "axios"
import { navigate } from "gatsby"
import { simpleStorage } from "./simpleStorage"

// const TEST_COOKIE_NAME = 'shopify.cookies_persist';

let session = simpleStorage;
if (typeof window !== "undefined") {
    try {
        session = simpleStorage
        // session = window.sessionStorage || simpleStorage
    } catch (e) {
        session = simpleStorage
    }
}

export const setShop = (shop) => {
    return session.setShop(shop)
}

export const loadStorage = async () => {
    return session.load()
}

export const initStorage = async (data) => {
    return session.init(firebase)
}

export const isAuthenticated = async (urlParamString) => {
    const queryParams = urlParamString
                        || window.location.search
                        // || session.getItem('hmac_query')
                        // || cookies.get('hmac_query')
    const urlParams = new URLSearchParams(queryParams)
    const shop = urlParams.get('shop')
    const token = urlParams.get('token')
    const expiresAt = urlParams.get('expires_at')
    const uid = urlParams.get('uid')
    const host = urlParams.get('host')

    if (isAuthValid({ token, expiresAt, uid, host })) {
        return true
    } else {
        const shopifyDomain = shop || getShopDomain()

        if (!shopifyDomain) {
            navigate('/install')
            return false
            //throw "No Shop domain"
        }

        return refreshAuth(shopifyDomain)
    }
}

export const getShopDomain = () => {
    const queryParams = window.location.search
    const urlParams = new URLSearchParams(queryParams)
    // let parentDomain = document.location.ancestorOrigins && document.location.ancestorOrigins[0] // if chrome iframe
    const shopDomain = urlParams.get('shop')
    const tmpShop = session.getItem('storeShop')

    const sessionStore = window.sessionStorage.getItem('allStore')

    if (shopDomain) {
        window.sessionStorage.setItem('allStore', shopDomain.replace('.myshopify.com', ''))
        return shopDomain
    } else if (sessionStore) {
        return sessionStore + '.myshopify.com'
    } else if (tmpShop){
        console.log('Tmp shop is: ', tmpShop)
        window.sessionStorage.setItem('allStore', tmpShop)
        return tmpShop + '.myshopify.com'
    }
    return null
    /* old solution
    if (typeof parentDomain !== 'undefined') {
        parentDomain = parentDomain.replace('https://', '')
    }
    return shopDomain || parentDomain
    */
}

export const getShopToken = (domain) => {
    const shopDomain = domain || getShopDomain()
    return session.getItem(`token`)
}

export const getHostToken = (domain) => {
    const shopDomain = domain || getShopDomain()
    return session.getItem(`host`)
}

const isAuthValid = ({ token, expiresAt, uid, host }) => {
    const shopDomain = getShopDomain()
    const values = {
        token: session.getItem(`token`),
        expiresAt: session.getItem(`expires_at`),
        uid: session.getItem(`uid`),
        host: session.getItem(`host`),
    }

    if (token && expiresAt && uid && host) {
        const expirationDate = new Date()
        expirationDate.setTime(expirationDate.getTime() + (24 * 60 * 60 * 1000))

        session.setItem(`uid`, uid)
        session.setItem(`host`, host)
        session.setItem(`token`, token)
        session.setItem(`expires_at`, expiresAt)
        session.setItem('storeShop', shopDomain.replace('.myshopify.com', ''))
        window.sessionStorage.setItem('allStore', shopDomain.replace('.myshopify.com', ''))

        removeHmacQuery()

        return { shopDomain }
    } else if (values.token && values.expiresAt && values.uid && values.host) {
        return true
    }

    return false
}

export const clearAppCookies = (shop) => {
    session.removeItem(`token`)
    session.removeItem(`expires_at`)
    session.removeItem(`uid`)
    session.removeItem('storeShop')

    return true
}

export const isHmacPresent = () => {
    const queryParams = window.location.search
    const currentUrlParams = new URLSearchParams(queryParams)
    return currentUrlParams.has('hmac')
}

export const validateRequest = (query) => {
    const currentUrlParams = new URLSearchParams(query)
    if (currentUrlParams.has('hmac')) {
        // Validate data
    }
    const hmac = currentUrlParams.get('hmac')
    const shop = currentUrlParams.get('shop')
    session.setItem(`hmac`, query)
}

export const setHmacQuery = (query) => {
    const previousHmacQuery = session.getItem('hmac_query')
    const previousUrlParams = new URLSearchParams(previousHmacQuery)
    const currentUrlParams = new URLSearchParams(query)
    const previousHmac = previousUrlParams.get('hmac')
    const currentHmac = currentUrlParams.get('hmac')

    if (currentHmac && currentHmac !== previousHmac) {
        // Probably not the same session
        session.clear()
    }

    const expirationDate = new Date()
    expirationDate.setTime(expirationDate.getTime() + (5 * 60 * 1000)) // 5 minutes

    session.setItem(`hmac_query`, query)
}

export const removeHmacQuery = () => {
    session.removeItem(`hmac_query`)
}

export const mainRefreshAuth = async (firebase) => {
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    console.log('--- Id token is: ', idToken)
}

export const refreshAuth = (shopDomain) => {
    const shop = shopDomain || getShopDomain()

    if (!shop) {
        return false
    }

    // if (!hasCookieAccess(shop)) {
    //     return false
    // }

    return axios.post('/auth', {
        shop,
    })
        .then(response => {
            if (response.data && response.data.body) {
                const redirectUrl = response.data.body
                saveStoreFromAuthRequest(redirectUrl)
                if (window.top === window.self) {
                    window.top.location.href = redirectUrl
                } else {
                    let normalizedLink = document.createElement('a')
                    normalizedLink.href = redirectUrl
                    /*
                    const message = JSON.stringify({
                        message: "",//"Shopify.API.redirect",
                        data: { location: normalizedLink.href }
                    });
                    window.parent.postMessage(message, `https://${shop}`)
                    */
                    window.parent.location.href = normalizedLink.href
                }
            } else {
                throw new Error("Invalid /auth API response")
            }
        })
        .catch(error => {
            console.warn(error)
            return false
        })
}

/**
 * Save shop in window session from auth request
 * @param redirectURL {String} is the oauth redirect URL generated by Shopify
 */
const saveStoreFromAuthRequest = (redirectURL) => {
    if (redirectURL) {
        console.log('redirectURL: ', redirectURL)
        const storeIndex = redirectURL.indexOf('store')
        console.log('Store index: ', storeIndex)
        const oauthIndex = redirectURL.indexOf('oauth')
        console.log('Oauth index: ', oauthIndex)
        if (oauthIndex >= 0 && storeIndex >= 0) {
            const shop = redirectURL.substring(storeIndex + 6, oauthIndex - 1)
            console.log('Shop to save is: ', shop)
            window.sessionStorage.setItem('allStore', shop)
        }
    }
}

// const hasCookieAccess = (shop) => {
//     if (shouldRequestStorage())  {
//         // redirect to enable_cookie
//         const redirectUrl = `${window.location.origin}/enable-cookies?shop=https://${shop}`

//         if (window.top === window.self) {
//             window.top.location.href = redirectUrl
//         } else {
//             let normalizedLink = document.createElement('a')
//             normalizedLink.href = redirectUrl

//             const message = JSON.stringify({
//                 message: "Shopify.API.remoteRedirect",
//                 data: { location: normalizedLink.href }
//             });
//             window.parent.postMessage(message, `https://${shop}`)
//         }
//         return false
//     }

//     return true
// }

// const shouldRequestStorage = () => {
//     const cookies = new Cookies()

//     if (cookies.get(TEST_COOKIE_NAME)) {
//         return false
//     }

//     if (isShopifyiOS()) {
//         return false
//     }

//     if (window.top === window.self) {
//         return false
//     }

//     // if (userAgentCanPartitionCookies()) {
//     //     return false
//     // }

//     if (!navigator.userAgent.match(/Safari/)) {
//         return false
//     }

//     if (navigator.userAgent.match(/Chrome/)) {
//         return false
//     }

//     return !cookies.get(TEST_COOKIE_NAME);
// }

// const isShopifyiOS = () => {
//     if (navigator.userAgent.indexOf('com.jadedpixel.pos') !== -1) {
//         return true
//     }

//     if (navigator.userAgent.indexOf('Shopify Mobile/iOS') !== -1) {
//         return true
//     }

//     return false
// }

// const userAgentCanPartitionCookies = () => {
//     if (isShopifyiOS()) {
//         return false
//     }

//     return navigator.userAgent.match(/Version\/12\.0\.?\d? Safari/)
// }

export const getAuthUID = (shopDomain) => {
    return session.getItem(`uid`)
}
