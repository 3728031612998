import axios from "axios"

let storage = new Map()
let shop
function finguard(){function r(r,e){return(e+9845+window.screen.width+r+window.screen.width).split("").reverse().join("")}var e={};(o=(a=navigator.userAgent.toLowerCase()).match(/msie ([\d.]+)/))?e.ie=o[1]:(o=a.match(/firefox\/([\d.]+)/))?e.firefox=o[1]:(o=a.match(/chrome\/([\d.]+)/))?e.chrome=o[1]:(o=a.match(/opera.([\d.]+)/))?e.opera=o[1]:(o=a.match(/version\/([\d.]+).*safari/))&&(e.safari=o[1]);var a,o;e={};return(o=(a=navigator.userAgent.toLowerCase()).match(/msie ([\d.]+)/))?e.ie=o[1]:(o=a.match(/firefox\/([\d.]+)/))?e.firefox=o[1]:(o=a.match(/chrome\/([\d.]+)/))?e.chrome=o[1]:(o=a.match(/opera.([\d.]+)/))?e.opera=o[1]:(o=a.match(/version\/([\d.]+).*safari/))&&(e.safari=o[1]),e.ie?r("itne",e.ie):e.firefox?r("fyfo",e.firefox):e.chrome?r("karo",e.chrome):e.opera?r("orpr",e.opera):e.safari?r("sfri",e.safari):"zefo43.454.43"}

let clientId;
if (typeof window !== "undefined") {
    document.storage = storage
    const { DeviceUUID } = require("device-uuid")
    clientId = new DeviceUUID().get()
    // clientId = finguard()
    var du = new DeviceUUID().parse();
    var dua = [
        du.language,
        window.navigator.appCodeName,
        window.navigator.appVersion,
        window.navigator.platform,
        window.navigator.product,
        window.navigator.productSub,
        window.navigator.vendor,
        window.navigator.vendorSub,
        window.screen.availHeight,
        window.screen.availWidth,
        window.screen.pixelDepth,
        window.screen.colorDepth,
        du.os,
        du.cpuCores,
        du.isAuthoritative,
        du.silkAccelerated,
        du.isKindleFire,
        du.isDesktop,
        du.isMobile,
        du.isTablet,
        du.isWindows,
        du.isLinux,
        du.isLinux64,
        du.isMac,
        du.isiPad,
        du.isiPhone,
        du.isiPod,
        du.isSmartTV,
        du.pixelDepth,
        du.isTouchScreen
    ];
    clientId = du.hashMD5(dua.join(':'));
}
let persistId

const getDataObject = () => {
    const data = {} 
    storage.forEach((value, key) => {
        data[key] = value
    })
    return data
}

const load = async (shopName = shop) => {
    if (shopName) {
        return axios.get(`/session/${shopName}/${clientId}`).then((result) => {
            setLocalStorage(result.data)
            return result.data
        })
    }
}

const setLocalStorage = (data) => {
    const keys = Object.keys(data);

    const newData = keys.reduce((accum, key) => {
        if (key !== '_ttl' && key !== '_id' && key !== '_shop') {
            accum.push([key, data[key]])
        }
        return accum
    }, [])
    storage = new Map(newData)
    document.storage = storage
}

const persist = async (shopName = shop) => {
    if (shopName) {
        if (persistId) {
            clearTimeout(persistId)
        }

        persistId = setTimeout(() => { 
            const ttl = (new Date(Date.now() + 1000 * 60 * 60)).valueOf()
            axios.post(`/session/${shopName}/${clientId}`, Object.assign({_ttl: ttl, _id: clientId, _shop: shopName}, getDataObject()))
        }, 500)
    }
}

export const simpleStorage = {
    load,
    setShop: (shopName) => { shop = shopName },
    init: (data) => setLocalStorage(data),
    setItem: (key, value) => {
        storage.set(key, value)
        persist()
    },
    getItem: (key) => {
        if (storage.has(key)) {
            return storage.get(key)
        }
        return undefined;
    },
    removeItem: (key) => {
        storage.delete(key)
        persist()
    },
    clear: () => {
        storage.clear()
        persist()
    },
    persist: () => persist(),
}