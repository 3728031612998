import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { Provider, Loading } from '@shopify/app-bridge-react'
import { AppProvider, Card, Spinner } from "@shopify/polaris"

import GraphqlProvider from "../providers/graphql"
import { getFirebase } from "../helpers/firebase"
import FirebaseContext from "../providers/firebase"

import "@shopify/polaris/dist/styles.css"

import {
    loadStorage,
    getShopToken,
    getHostToken,
    getShopDomain,
    isAuthenticated,
    // setHmacQuery,
    setShop,
    getAuthUID,
} from "../helpers/auth"

class AppLayout extends React.Component {
    constructor(props) {
        super(props)

        let shop = null

        if (typeof window !== 'undefined') {
            shop = getShopDomain()

            if (!shop || shop === null || typeof shop == "undefined") {
                navigate(`/install/`, { replace: true })
            }
            setShop(shop)
        }

        this.state = {
            shop,
            token: null,
            isLoading: true,
        }
    }

    componentDidMount = async () => {
        this.getSession()
    }

    getSession = () => {
        loadStorage().then((result) => {
            this.checkAuth();
        })
    }

    checkAuth = async () => {
        let isAuth = false
        const queryParams = window.location.search
        const { shop: shopDomain } = this.state

        // if (queryParams && queryParams.includes('shop')) {
        //     setHmacQuery(queryParams)
        // }

        isAuth = await isAuthenticated(queryParams)

        const token = getShopToken(shopDomain)
        if (isAuth) {
            const userToken = getAuthUID(shopDomain);

            if (userToken) {
                const app = import('firebase/app')
                const auth = import('firebase/auth')
                const database = import('firebase/firestore')

                Promise.all([app, auth, database]).then(values => {
                    const { firebase, user, isAuth } = getFirebase(values[0].default, userToken)

                    isAuth.then(auth => {
                        this.setState({
                            isLoading: auth !== true,
                        })
                    })
                    this.setState({
                        firebase,
                        token,
                        // isLoading: false,
                    })
                })
            } else {
                // show login button/screen -- redirect to reauth
                console.warn('no user token found')
            }
        }
    }

    render() {
        const { shop, token, isLoading, firebase } = this.state
        //let appTitle = '' // convert to new Gatsy useStaticQuery hook
        let content = ''

        if (!shop || shop === null || typeof shop == "undefined") {
            return (
                <Spinner/>
            )
        }

        const providerConfig = {apiKey: process.env.GATSBY_SHOPIFY_APP_API_KEY, host: getHostToken(), forceRedirect: true}
        const loadingComp = !shop && !token ? <Loading /> : null
        if (isLoading) {
            content = (
                <Card>
                    <Card.Section>
                        <Spinner/>
                    </Card.Section>
                </Card>
            )
        } else {
            content = (
                <Provider config={providerConfig}>
                    {loadingComp}
                    <FirebaseContext.Provider value={{firebase, shop}}>
                        <GraphqlProvider
                            shop={shop}
                            token={token}
                        >
                            {this.props.children}
                        </GraphqlProvider>
                    </FirebaseContext.Provider>
                </Provider>
            )
        }

        return (
            <AppProvider>
                {content}
            </AppProvider>
        )
    }
}

AppLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AppLayout
