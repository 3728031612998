
const config = {
    apiKey: `${process.env.GATSBY_FIREBASE_WEB_API_KEY}`,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    // databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
    // appId: process.env.GATSBY_FIREBASE_APP_ID,
    // measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
};

let resolveAuth
let resolveUser

let firebaseInstance
let firebaseAuth = new Promise((resolve) => {
    resolveAuth = resolve
})
let firebaseUser = new Promise((resolve) => {
    resolveUser = resolve
})

export const getFirebase = (firebase, UID) => {
    if (!firebaseInstance) {
        firebase.initializeApp(config)
        firebase.auth().signInWithCustomToken(UID)
            .then((userCredential) => {
                resolveUser(userCredential.user)
                resolveAuth(true)
            })
            .catch(function (error) {
                console.warn(error.code);
                console.warn(error.message);
            });
    
        firebaseInstance = firebase
    }
    return {firebase: firebaseInstance, user: firebaseUser, isAuth: firebaseAuth}
}
